import React from 'react';
import styled from 'styled-components';


const StyledLink = styled.a`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.cwCoral};
  padding: 4px;
  font-weight: 700;
  outline: none;
  display: inline-block;
  text-decoration: none;
  text-transform: lowercase;
  transition: all 300ms ease-in-out;
  box-shadow: 4px 4px ${({ theme }) => theme.colors.linkShadow};
  
  :visited {
    text-decoration: none;
  }
  
  :hover, :focus {
    box-shadow: 6px 6px ${({ theme }) => theme.colors.linkShadow};
    transform: translate(0, -2px);
  }
  
  :active {
    box-shadow: 1px 1px ${({ theme }) => theme.colors.linkShadow};
    transform: translate(0, 2px);
  }
  
  ::selection {
    color: ${({ theme }) => theme.colors.cwCoralSelection};
    background-color: ${({ theme }) => theme.colors.whiteSelection};
  } 
`;

interface CustomLinkProps {
  href: string,
  children: string,
  target?: string,
}

const CustomLink = ({
  href, children, target = '_self', ...otherProps
}: CustomLinkProps) => (
  <StyledLink
    href={href}
    target={target}
    {...otherProps}
  >
    {children}
  </StyledLink>
);

export default CustomLink;
