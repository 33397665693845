import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { mainTheme } from './src/theme/mainTheme';


export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={mainTheme}>
    {element}
  </ThemeProvider>
);

wrapRootElement.propTypes = {
  element: PropTypes.node.isRequired,
};
