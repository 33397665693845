import { DefaultTheme, ScreenSizeBreakpoints } from 'styled-components';


const screenSizeWidthWithoutPx: Record<ScreenSizeBreakpoints, number> = {
  extraSmall: 576,
  small: 768,
  medium: 992,
  large: 1200,
  extraLarge: 1500,
  xExtraLarge: 1700,
};

// helper function to streamline modifying values of screenSizeWidth
function getScreenSizeWidthObject(): Record<ScreenSizeBreakpoints, string> {
  return Object.entries(screenSizeWidthWithoutPx).reduce<Record<ScreenSizeBreakpoints, string>>(
    (accumulator, [key, val]) => ({
      ...accumulator,
      [key as ScreenSizeBreakpoints]: `${val}px`,
    }),
    {} as Record<ScreenSizeBreakpoints, string>
  );
}

export const mainTheme: DefaultTheme = {
  // edit theme object here
  colors: {
    blue: '#4196B4',
    lightBlue: '#94BFD1',
    darkBlue: '#19758E',
    socialMediaBar: '#4296B4',
    white: '#ffffff',
    cwCoral: '#EB6668',
    lightCoral: '#ED8C8B',
    darkGray: '#4A5659',
    lightGray: '#6A7C85',
    cleanGray: '#E1E5E7',
    linkShadow: 'rgba(35, 35, 35, 0.2)',
    washedWhite: 'rgba(255,255,255,0.5)',
    cwCoralSelection: 'rgba(235,102,104,0.99)',
    whiteSelection: 'rgba(255,255,255,0.99)',
    whiteGradient: 'rgba(255, 255, 255, 0.1)',
    gradientZero: 'rgba(0, 0, 0, 0)',
  },
  screenSizeWidthWithoutPx,
  screenSizeWidth: getScreenSizeWidthObject(),
};

