import { wrapRootElement as wrapper } from './root-wrapper';


if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', {
    offset: 80,
    easing: 'easeInOutCubic',
    speed: 600,
  });
}

export const wrapRootElement = wrapper;

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]', {
    offset: 80,
    easing: 'easeInOutCubic',
    speed: 600,
  });
}

export const onInitialClientRender = () => {
  window.scrollTo(0, 0);
};
