// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-tsx": () => import("./../../../src/pages/404.en.tsx" /* webpackChunkName: "component---src-pages-404-en-tsx" */),
  "component---src-pages-404-pl-tsx": () => import("./../../../src/pages/404.pl.tsx" /* webpackChunkName: "component---src-pages-404-pl-tsx" */),
  "component---src-pages-contact-en-tsx": () => import("./../../../src/pages/contact.en.tsx" /* webpackChunkName: "component---src-pages-contact-en-tsx" */),
  "component---src-pages-contact-pl-tsx": () => import("./../../../src/pages/contact.pl.tsx" /* webpackChunkName: "component---src-pages-contact-pl-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-index-pl-tsx": () => import("./../../../src/pages/index.pl.tsx" /* webpackChunkName: "component---src-pages-index-pl-tsx" */),
  "component---src-pages-information-clause-en-tsx": () => import("./../../../src/pages/information-clause.en.tsx" /* webpackChunkName: "component---src-pages-information-clause-en-tsx" */),
  "component---src-pages-information-clause-pl-tsx": () => import("./../../../src/pages/information-clause.pl.tsx" /* webpackChunkName: "component---src-pages-information-clause-pl-tsx" */),
  "component---src-pages-portfolio-en-tsx": () => import("./../../../src/pages/portfolio.en.tsx" /* webpackChunkName: "component---src-pages-portfolio-en-tsx" */),
  "component---src-pages-portfolio-pl-tsx": () => import("./../../../src/pages/portfolio.pl.tsx" /* webpackChunkName: "component---src-pages-portfolio-pl-tsx" */)
}

